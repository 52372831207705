import {
    axios
} from '@/utils/request'

//移民群众生产生活条件变化情况

// 获取列表数据
export function _ProductionLivingPage(params) {
    return axios({
        url: '/ProductionLiving/Page',
        method: 'get',
        params,
    })
}

// 新增
export function _ProductionLivingAdd(params) {
    return axios({
        url: '/ProductionLiving/Add',
        method: 'post',
        data: params,
    })
}

// 编辑
export function _ProductionLivingEdit(params) {
    return axios({
        url: '/ProductionLiving/Edit',
        method: 'post',
        data: params,
    })
}


// 删除
export function _ProductionLivingDelete(params) {
    return axios({
        url: '/ProductionLiving/Delete',
        method: 'post',
        data: params,
    })
}



//详情
export function _ProductionLivingInfo(params) {
    return axios({
        url: '/ProductionLiving/Info',
        method: 'get',
        params,
    })
}


// excel导入
export function _ProductionLivingImport(params) {
    return axios({
        url: '/ProductionLiving/Import',
        method: 'get',
        params,
    })
}


// excel导出
export function _ProductionLivingExport(params) {
    return axios({
        url: '/ProductionLiving/Export',
        method: 'get',
        params,
        responseType: 'blob',
    })
}





